<template>
  <div>
    <div>
      <k-header-section
        header-text="Collections"
        :item-label="title"
        :sub-nav-bar-items="navDatas"
        :total-items-count="totalItemsCount"
        :current-page-count="currentPageCount"
      />
    </div>
    <div>
      <router-view @update-total-items="updateTotalItems" />
    </div>
  </div>
</template>

<script>
import { KHeaderSection } from '@kingpin-global/kingpin-ui'
import collectionsNavBarContent from '@/assets/app-content/collections-navbar-content.json'

export default {
  name: 'Collections',
  components: {
    KHeaderSection,
  },
  data() {
    return {
      navDatas: collectionsNavBarContent.navBarItems,
      currentPageCount: 0,
      totalItemsCount: 0,
      title: '',
    }
  },
  methods: {
    updateTotalItems(currentPageCount, totalItemsCount, title) {
      this.currentPageCount = currentPageCount
      this.totalItemsCount = totalItemsCount
      this.title = title
    },
  },
}
</script>
